import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom'
import Navbar from './Navbar';
import Footer from './Footer';
import '../css/HyperLeague.css';
import Logo from '../img/Recurso 2@4x.png';
import CONFIG from '../Config.js';

const ImageWithFallback = ({ src, alt, className }) => {
  const [imgSrc, setImgSrc] = useState(src);
  
  useEffect(() => {
    setImgSrc(src);
  }, [src]);
  
  const handleError = () => {
    if (imgSrc.endsWith('.png')) {
      setImgSrc(imgSrc.replace('.png', '.jpg'));
    }
  };
  
  return <img src={imgSrc} alt={alt} className={className} onError={handleError} />;
};

const generateGroupImages = (groupName, stage) => {
  const formattedGroupName = groupName
    .replace(/^U(\d+)/, 'SUB$1')
    .replace(/ /g, '_');
    
  const formattedStage = stage.toLowerCase();
  const SERVER_BASE_URL = CONFIG.SERVER_BASE_URL;

  const getImage = (path) => {
    return `${SERVER_BASE_URL}/${stage}/${formattedGroupName}/${path}.png`;
  };

  const getImageArray = (baseName, count) => {
    return Array(count).fill().map((_, i) => getImage(`${baseName}_${i + 1}`));
  };

  return {
    calendarioResultados: getImageArray('calendario_resultado', 6),
    clasificacionRendimientos: stage === 'apertura' ? getImageArray('clasificacion_rendimientos', 3) : [],
    clasificacionRendimientosDesktop: stage === 'apertura' ? getImage('clasificacion_rendimientos_desktop') : null,
    clasificacionRendimientosMobile: stage === 'apertura' ? getImage('clasificacion_rendimientos_mobile') : null,
    clasificacionDesktop: getImage('clasificacion_desktop'),
    clasificacionMobile: getImage('clasificacion_mobile'),
    team: getImageArray('team', 7),
    finalDesktop: getImage('final_desktop'),
    finalMobile: getImage('final_mobile')
  };
};

const validGroups = [
  'SUB19 Grupo 1',
  'SUB19 Grupo 2',
  'SUB16 Grupo 1',
  'SUB16 Grupo 2',
  'SUB19 Grupo 3',
  'SUB19 Grupo 4',
  'Femenino',
];


const groupTeams = {
  apertura: {
    "SUB19 Grupo 1": ["Powerade","C.E. Poble Sec", "Premier", "Congress", "Singuerlin", "Sant Andreu", "C.E. Europa"],
    "SUB19 Grupo 2": ["Aquarius", "P.B. Anguera", "AT Sant Just", "I. Americano", "FAF Hospitalet", "Joves Sabadell", "Sant Andreu"],
    "SUB16 Grupo 1": ["Powerade","AT Sant Just", "Singuerlin", "Mirasol", "Sant Andreu", "Fredrikstad", "N. Tarragona"],
    "SUB16 Grupo 2": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
    "SUB19 Grupo 3": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
    "SUB19 Grupo 4": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
    "SUB14 Grupo 1": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"],
    "Femenino": ["Aquarius", "A.P.A. Poble Sec", "Congress", "P.B. Anguera", "Premier", "FAF Hospitalet", "C.E. Europa"]
  },
  meridiano: {
    "SUB19 Grupo 1": ["Powerade S19", "AC Joves VO", "Premier", "CE Hospitalet", "Sant Just", "Sant Andreu", "C.E. Europa"],
    "SUB19 Grupo 2": ["Aquarius S19", "C.E. Europa", "CE Hospitalet", "Premier", "Mercantil CE", "Sant Just", "Sant Andreu"],
    "SUB16 Grupo 1": ["Powerade S16", "Sant Just", "CE Hospitalet", "CE Europa", "Sant Andreu A", "UE SA Club Z", "Premier"],
    "SUB16 Grupo 2": ["Aquarius S16", "Sant Just", "CE Hospitalet", "FAF Hospitalet", "Premier", "Mercantil", "C.E. Europa"],
    "SUB19 Grupo 3": ["Cocacola S19", "Sant Andreu", "Mercantil", "CE Hospitalet", "Veterans", "FAF Hospitalet", "C.E. Europa"],
    "SUB19 Grupo 4": ["Sprite S19", "Sant Andreu", "Premier", "CE Hospitalet", "Sant Just", "Singuerlin", "C.E. Europa"],
    "SUB14 Grupo 1": ["Powerade S14", "Sant Andreu", "Premier", "CE Hospitalet", "Mira-Sol", "FAF Hospitalet", "C.E. Europa"],
    "Femenino": ["C.E. Europa", "Sant Cugat", "Levante BDN A", "Fontsanta A", "Mercantil", "Levante BDN B", "Fontsanta B"]
  },  
  clausura: {
    "SUB19 Grupo 1": ["Equipo C1", "Equipo C2", "Equipo C3", "Equipo C4", "Equipo C5", "Equipo C6", "Equipo C7"],
    "SUB19 Grupo 2": ["Equipo C8", "Equipo C9", "Equipo C10", "Equipo C11", "Equipo C12", "Equipo C13", "Equipo C14"],
  }
};


const getTeamNames = (groupName, stage) => {
  return groupTeams[stage]?.[groupName] || [];
};

const SPECIAL_TEAMS = ['SUB19 Grupo 3', 'SUB19 Grupo 4', 'SUB14 Grupo 1','Femenino'];

const HyperLeague = () => {
  const { group } = useParams();
  const normalizedGroup = group.replace(/-/g, ' ');
  const [isClausuraDisabled, setIsClausuraDisabled] = useState(true);
  const isSpecialTeam = SPECIAL_TEAMS.includes(normalizedGroup);
  const [activeTab, setActiveTab] = useState('meridiano')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [groupImages, setGroupImages] = useState(null);

  useEffect(() => {
    if (isSpecialTeam && activeTab === 'apertura') {
      setActiveTab('meridiano');
    }
  }, [normalizedGroup, isSpecialTeam]);

  useEffect(() => {
    const images = generateGroupImages(normalizedGroup, activeTab);
    setGroupImages(images);
  }, [normalizedGroup, activeTab]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!validGroups.includes(normalizedGroup)) {
    return <Navigate to="/404" />;
  }

  return (
    <div className="home-container">
      <Navbar />
      <main className="league-content">
        <section className="hero-league-section">
          <div className="hero-league-content">
            <div className="hero-league-text">
              <h1>{normalizedGroup}</h1>
            </div>
          </div>
        </section>

        <div className="tab-navigation content-wrapper">
          <button 
            className={`tab-button ${activeTab === 'apertura' ? 'active' : ''} ${isSpecialTeam ? 'disabled' : ''}`} 
            onClick={isSpecialTeam ? null : () => setActiveTab('apertura')}
            disabled={isSpecialTeam}
          >
            APERTURA
          </button>
          <button 
            className={`tab-button ${activeTab === 'meridiano' ? 'active' : ''}`} 
            onClick={() => setActiveTab('meridiano')}
          >
            MERIDIANO
          </button>
          <button 
            className={`tab-button ${activeTab === 'clausura' ? 'active' : ''} ${isClausuraDisabled ? 'disabled' : ''}`} 
            onClick={isClausuraDisabled ? null : () => setActiveTab('clausura')}
            disabled={isClausuraDisabled}
          >
            CLAUSURA
          </button>
        </div>

        <section className="tab-content content-wrapper">
          {activeTab === 'apertura' && (
            <div>
              <h1 className="section-title">EQUIPOS</h1>
              <div className="teams-container">
              {groupImages?.team?.map((src, index) => (
                <div key={index} className="team-item">
                  <div className="team-icon-wrapper">
                    <ImageWithFallback 
                      src={src} 
                      alt={`Equipo ${index + 1}`} 
                      className="teams-icon"
                    />
                  </div>
                  <p className="team-label">
                    {getTeamNames(normalizedGroup, activeTab)[index] || `Equipo ${index + 1}`}
                  </p>
                </div>
              ))}
            </div>
              <div className="logo-position">
                <img src={Logo} alt="Logo" className="logo-image" />
              </div>
              <h1 className="section-title">CALENDARIOS Y RESULTADOS</h1>
              <div className="calendar-grid">
                {groupImages?.calendarioResultados.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <ImageWithFallback 
                        src={src} 
                        alt={`Calendario resultado ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>

              <h1 className="section-title">CLASIFICACIÓN</h1>
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionMobile && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionMobile} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionDesktop && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionDesktop} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                )}
              </div>

              <h1 className="section-title">
                CLASIFICACIÓN POR DESEMPEÑOS
                <p className="centered-text">Defensa + Construcción + Ataque</p>
              </h1>
              
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionRendimientosMobile && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionRendimientosMobile} 
                      alt="clasificación por desempeños" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionRendimientosDesktop && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionRendimientosDesktop} 
                      alt="clasificación por desempeños" 
                      className="league-image" 
                    />
                  )
                )}
              </div>

              <div className="calendar-grid">
                {groupImages?.clasificacionRendimientos.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <ImageWithFallback 
                        src={src} 
                        alt={`Clasificación desempeños ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeTab === 'meridiano' && (
            <div>
              <h1 className="section-title">EQUIPOS</h1>
              <div className="teams-container">
                {groupImages?.team?.map((src, index) => (
                  <div key={index} className="team-item">
                    <div className="team-icon-wrapper">
                      <ImageWithFallback 
                        src={src} 
                        alt={`Equipo ${index + 1}`} 
                        className="teams-icon"
                      />
                    </div>
                    <p className="team-label">
                      {getTeamNames(normalizedGroup, activeTab)[index] || `Equipo ${index + 1}`}
                    </p>
                  </div>
                ))}
              </div>
              <div className="logo-position">
                <img src={Logo} alt="Logo" className="logo-image" />
              </div>

              <h1 className="section-title">FINAL</h1>
                <div className="league-image-container">
                  {isMobile ? (
                    groupImages?.finalMobile && (
                      <ImageWithFallback 
                        src={groupImages.finalMobile} 
                        alt="Final" 
                        className="league-image" 
                      />
                    )
                  ) : (
                    groupImages?.finalDesktop && (
                      <ImageWithFallback 
                        src={groupImages.finalDesktop} 
                        alt="Final" 
                        className="league-image" 
                      />
                    )
                  )}
                </div>

              <h1 className="section-title">CALENDARIOS Y RESULTADOS</h1>
              <div className="calendar-grid">
                {groupImages?.calendarioResultados.map((src, index) => (
                  <div key={index} className="calendar-item">
                    {src && (
                      <ImageWithFallback 
                        src={src} 
                        alt={`Calendario resultado ${index + 1}`} 
                        className="calendar-image" 
                      />
                    )}
                  </div>
                ))}
              </div>

              <h1 className="section-title">CLASIFICACIÓN</h1>
              <div className="league-image-container">
                {isMobile ? (
                  groupImages?.clasificacionMobile && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionMobile} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                ) : (
                  groupImages?.clasificacionDesktop && (
                    <ImageWithFallback 
                      src={groupImages.clasificacionDesktop} 
                      alt="clasificación" 
                      className="league-image" 
                    />
                  )
                )}
              </div>
              
            </div>
          )}
          {activeTab === 'clausura' && <p>Contenido de Clausura...</p>}
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HyperLeague;